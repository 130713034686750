import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { PrimaryButton } from "./PrimaryButton";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { FlatButton } from "./FlatButton";
import { SizedBox } from "./SizedBox";
import { AppContext } from "../contexts/AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from "./IconButton";
import { IoSunnySharp } from "react-icons/io5";
import { BsFillMoonFill } from "react-icons/bs";
import { MdOutlineInsertLink } from "react-icons/md";
import { useAppColors } from "../utils/useAppColors";
import OptionsBottomSheet from "./OptionsBottomSheet";
import { FaUser } from "react-icons/fa";

const Logo = "https://avatars.githubusercontent.com/u/115368968?s=40&v=4"
const StuckBannerImg = styled.img`
  width: 145px;
  height: auto;
`;

const dappUrl = "stuck.cash";

const StuckNavWrapper = styled.nav`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  color: #130d0d;
  font-size: 20px;
  padding: 20px 40px 20px 40px;
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  -o-box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
`;

const StuckNavWrapperMobile = styled.nav`
  width: 100%;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  color: #130d0d;
  font-size: 20px;
  padding: 10px 0 10px 0;
`;
const StuckNavInnerWrapper = styled.nav`
  width: 80%;
`;

const StuckNavInnerWrapperMobile = styled.nav`
  width: 90%;
`;

const StuckWord = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "32px")};
  color: ${(props) => props.color} !important;
  font-weight: bold;
`;
const StuckBond = styled.div`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "32px")};
  color: ${(props) => props.color};
  font-weight: bold;
`;

const StuckTitleWrapper = styled.a`
  cursor: pointer;
  text-decoration: none;
`;

const LogoImage = styled.img`
  height: 48px;
  width: 48px;
`;

const LogoImageMobile = styled.img`
  height: 28px;
  width: 28px;
`;

export const StuckNav = ({ name, placeholder, isLoggingIn }) => {
  const location = useLocation();

  const navigate = useNavigate();
  const { theme, setTheme } = useContext(AppContext);
  const { user, account, logout, login } = useContext(AppContext);
  const colors = useAppColors();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const [deepLink, setDeepLink] = useState(
    "https://metamask.app.link/dapp/" +
      dappUrl +
      location.pathname +
      location.search
  );

  useEffect(() => {
    setDeepLink(
      "https://metamask.app.link/dapp/" +
        dappUrl +
        location.pathname +
        location.search
    );
  }, [location]);

  useEffect(() => {}, [account]);
  return (
    <div>
      <BrowserView>
        <StuckNavWrapper
          backgroundColor={colors.navColor}
          className="d-flex justify-content-center"
        >
          <StuckNavInnerWrapper className="d-flex justify-content-between">
            <StuckTitleWrapper
              className="d-flex"
              href="https://stuck.cash/"
            >
              <LogoImage src={Logo} />
              {/* <StuckWord>🔗</StuckWord> */}
              <SizedBox width={8} />
              <StuckWord
                color={theme === "light" ? "black" : "white"}
                className="scheduleNavTitle"
              >
                Stuck
              </StuckWord>
              <StuckBond
                color={colors.primaryColor}
                className="scheduleNavTitle"
              >
                Cash
              </StuckBond>
            </StuckTitleWrapper>

            <div className="d-flex align-items-center">
              {/* <IconButton
                Icon={theme === "light" ? BsFillMoonFill : IoSunnySharp}
                onClick={() => {
                  setTheme(theme === "light" ? "dark" : "light");
                }}
              /> */}
              {/* {user && account && (
                <IconButton
                  Icon={FaUser}
                  onClick={() => {
                    setOptionsOpen(true);
                  }}
                />
              )} */}
              <SizedBox width={32} />
              <PrimaryButton
                isLoading={isLoggingIn}
                onClick={
                  user && account
                    ? () => {
                        setOptionsOpen(true);
                      }
                    : login
                }
                text={
                  user && account && typeof account === "string"
                    ? `${account.slice(0, 5) + "..." + account.slice(38, 42)}`
                    : "Connect Wallet"
                }
                padding={"10px 20px 10px 20px"}
                borderRadius={"18px"}
              />
            </div>
          </StuckNavInnerWrapper>
        </StuckNavWrapper>
      </BrowserView>
      <MobileView>
        <StuckNavWrapperMobile
          backgroundColor={colors.navColor}
          className="d-flex justify-content-center"
        >
          <StuckNavInnerWrapperMobile className="d-flex justify-content-between align-items-center">
            <StuckTitleWrapper
              className="d-flex"
              href="https://stuck.cash/"
            >
              <LogoImageMobile src={Logo} />
              {/* <StuckWord fontSize="20px">🔗</StuckWord> */}
              <SizedBox width={8} />
              <StuckWord
                fontSize="20px"
                color={theme === "light" ? "black" : "white"}
                className="scheduleNavTitle"
              >
                Stuck
              </StuckWord>
              <StuckBond
                fontSize="20px"
                color={colors.primaryColor}
                className="scheduleNavTitle"
              >
                Cash
              </StuckBond>
            </StuckTitleWrapper>
            {isMobile && !window.ethereum ? (
              <a href={deepLink}>
                <PrimaryButton
                  // onClick={user ? logout : login}
                  isLoading={isLoggingIn}
                  text={
                    user && typeof account === "string"
                      ? `${account.slice(0, 5) + "..." + account.slice(38, 42)}`
                      : "Connect"
                  }
                />
              </a>
            ) : (
              <>
                {user && account ? (
                  <div className="d-flex align-items-center">
                    {/* <IconButton
                      Icon={FaUser}
                      onClick={() => {
                        setOptionsOpen(true);
                      }}
                    /> */}
                    <SizedBox width={12} />
                    <IconButton
                      Icon={MdOutlineInsertLink}
                      onClick={() => setOptionsOpen(true)}
                      color={"white"}
                      backgroundColor={colors.primaryColor}
                    />
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    {/* {user && account && (
                      <IconButton
                        Icon={FaUser}
                        onClick={() => {
                          setOptionsOpen(true);
                        }}
                      />
                    )} */}
                    <PrimaryButton
                      onClick={user ? logout : login}
                      isLoading={isLoggingIn}
                      text={
                        user && typeof account === "string"
                          ? `${
                              account.slice(0, 5) +
                              "..." +
                              account.slice(38, 42)
                            }`
                          : "Connect"
                      }
                    />
                  </div>
                )}
              </>
            )}
          </StuckNavInnerWrapperMobile>
        </StuckNavWrapperMobile>
      </MobileView>
      <OptionsBottomSheet open={optionsOpen} setOpen={setOptionsOpen} />
    </div>
  );
};
