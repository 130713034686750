import {
    BrowserRouter as Router,
    Routes,
    Route,
    useNavigate,
    useLocation,
    Navigate,
  } from "react-router-dom";
import Home from "../pages/Home";

export default function MainRoutes({ isLoggingIn }) {
    const location = useLocation();
    return (
      <Routes>
        
          <Route path={"/"} element={<Home isLoggingIn={isLoggingIn} />} />
     
      </Routes>
    );
  }
  