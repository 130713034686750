import React from "react";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { Alert, AlertColor } from "@mui/material";

export default function StuckSnackbar({ open, setOpen, severity, message }) {
  //severity
  const handleClose = () => {
    setOpen(false);
  };

  const handleClickEvent = () => {
    setOpen(true);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%", fontFamily: "Poppins" }}
      >
        {message.toString()}
      </Alert>
    </Snackbar>
  );
}
