import styled from "styled-components";
import { useContext, useState } from "react";
import { AppContext } from "../contexts/AppContext";
import { useAppColors } from "../utils/useAppColors";

export const IconButtonWrapper = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  padding: 8px;
  cursor: pointer;
  border-radius: 500px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  border: 2px solid ${(props) => props.borderColor};
`;

export default function IconButton({
  Icon,
  onClick,
  backgroundColor,
  color,
  borderColor,
}) {
  const colors = useAppColors();
  return (
    <div>

        <IconButtonWrapper
          color={color ? color : colors.titleColor}
          backgroundColor={
            backgroundColor ? backgroundColor : colors.iconButtonColor
          }
          borderColor={borderColor ? borderColor : colors.iconBorderColor}
          onClick={onClick}
        >
          <Icon />
        </IconButtonWrapper>
    </div>
  );
}
