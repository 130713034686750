import { useState, useEffect, useContext } from "react";
import { AppContext } from "../contexts/AppContext";

const primaryColor = "#4a9c4e";

const primaryColorLight = "#1Ab452f7";

const lightPurple = "#fcf9fe";

const textColor = "#8E8E8E";

const titleColor = "#130D0D";

const textFieldColor = "#DEDEDE";

const successGreen = "#37DC84";

const backgroundColor = "#fef8f7";

const cardBackgroundColor = "#ecfff9";

const calendarDayColor = "#F0DCFF";

const calendarHighlightColor = "#d6b6ee";

const calendarCurrentColor = "#c2c3f6";

const navColor = "#ffffff";

const iconButtonColor = "#ffffff";

const iconBorderColor = "#000000";

const darkBackgroundColor = "#030303";

const darkTitleColor = "#FFFFFF";

const darkTextColor = "#889194";

const darkCardColor = "#101010";

const darkIconButtonColor = "#1f1f1f";
const darkListItemColor = "#1f1f1f";

const darkNavColor = darkCardColor;
const darkIconBorderColor = "#000000";

const errorRed = "#E12D39";

const lightColors = {
  primaryColor: primaryColor,
  primaryColorLight: primaryColorLight,
  textColor: textColor,
  titleColor: titleColor,
  textFieldColor: textFieldColor,
  successGreen: successGreen,
  backgroundColor: backgroundColor,
  cardBackgroundColor: cardBackgroundColor,
  navColor: navColor,
  iconButtonColor: iconButtonColor,
  iconBorderColor: iconBorderColor,
  timeCardColor: "white",
  listItemColor: "white",
  listItemBorderColor: textColor,
  textFieldTextColor: titleColor,
  activeTextFieldColor: "#D8D9CF",
  inputNameBackgroundColor: "white",
  inputNameColor: textColor,
  textFieldBorderColor: textColor,
  lightPurple: lightPurple,
  errorRed: errorRed,
  calendarCurrentColor: calendarCurrentColor,
  calendarDayColor: calendarDayColor,
  calendarHighlightColor: calendarHighlightColor,
};

const darkColors = {
  primaryColor: primaryColor,
  primaryColorLight: primaryColorLight,
  textColor: darkTextColor,
  titleColor: darkTitleColor,
  textFieldColor: darkListItemColor,
  successGreen: successGreen,
  backgroundColor: darkBackgroundColor,
  cardBackgroundColor: darkCardColor,
  navColor: darkNavColor,
  iconButtonColor: darkIconButtonColor,
  iconBorderColor: iconBorderColor,
  timeCardColor: darkCardColor,
  listItemColor: darkListItemColor,
  listItemBorderColor: darkCardColor,
  textFieldTextColor: darkTitleColor,
  activeTextFieldColor: darkListItemColor,
  inputNameBackgroundColor: "transparent",
  inputNameColor: "white",
  textFieldBorderColor: "transparent",
  lightPurple: lightPurple,
  errorRed: errorRed,
  calendarCurrentColor: calendarCurrentColor,
  calendarDayColor: calendarDayColor,
  calendarHighlightColor: calendarHighlightColor,
};

export function useAppColors() {
  const { theme } = useContext(AppContext);
  const [colors, setColors] = useState(
    theme === "light" ? lightColors : darkColors
  );

  useEffect(() => {
    if (!theme) {
      return;
    }
    if (theme !== "dark") {
      setColors(lightColors);
    } else {
      setColors(darkColors);
    }
  }, [theme]);

  return colors;
}
