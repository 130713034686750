// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, signInWithCustomToken } from "firebase/auth";

import { v4 as uuidv4 } from "uuid";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpXamdUNdD1TvYtpp3faLNLvKhX11Y93U",
  authDomain: "stuck-cash.firebaseapp.com",
  projectId: "stuck-cash",
  storageBucket: "stuck-cash.appspot.com",
  messagingSenderId: "99074737432",
  appId: "1:99074737432:web:f6e709a5508cbc14940a6d",
  measurementId: "G-Z7HMS2H2FH"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage();
const analytics = getAnalytics(app);
const auth = getAuth();

function generateUserAvatarMediaPath(userId) {
  return `/avatars/${userId}/`;
}

async function updateAvatarImage(imageToUpload, userId) {
  try {
    const imagePath = generateUserAvatarMediaPath(userId);
    const imageFileName = imagePath + uuidv4();
    const firebaseStorageRef = ref(storage, imageFileName);
    const response = await uploadBytes(firebaseStorageRef, imageToUpload);
    const downloadUrl = await getDownloadURL(response.ref);
    return { downloadUrl: downloadUrl };
  } catch (e) {
    return { error: e };
  }
}

async function signinWithFirebaseToken(jwt) {
  const response = await signInWithCustomToken(auth, jwt);
}

async function log({ eventName, eventParams }) {
  logEvent(analytics, eventName, eventParams);
}

const firebaseUtils = {
  app,
  storage,
  updateAvatarImage,
  signinWithFirebaseToken,
  log,
};
export default firebaseUtils;
