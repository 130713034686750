import React from "react";
import styled from "styled-components";

const DynamicImageWrapper = styled.img`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border-radius: ${(props) => props.borderRadius};
  border: 6px solid white;
`;

const fallbackImage =
  "https://firebasestorage.googleapis.com/v0/b/scedule-bond.appspot.com/o/images%2Fphunk6053.png?alt=media&token=04c7e1c7-e65e-4f0f-9662-bacca51f8113";

export const DynamicImage = ({
  imageUrl,
  width,
  height,
  borderRadius,
  hasShadow,
}) => {
  return (
    <div style={{ width: width, height: height }}>
      {hasShadow ? (
          <DynamicImageWrapper
            className="lighter-active-gradient"
            width={width}
            height={height}
            hasShadow={hasShadow}
            borderRadius={borderRadius}
            src={imageUrl ? imageUrl : fallbackImage}
          />
      ) : (
        <DynamicImageWrapper
          className="lighter-active-gradient"
          width={width}
          height={height}
          hasShadow={hasShadow}
          borderRadius={borderRadius}
          src={imageUrl ? imageUrl : fallbackImage}
        />
      )}
    </div>
  );
};
