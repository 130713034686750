import ky from "ky";
const rootUrl = "http://localhost:8888/v1";

async function apiCall(path, body, jwt) {
    const headers = {
        "Content-Type": "application/json",
        authorization: jwt ? jwt : undefined,
    };

    try {
        const response = await ky
            .post(rootUrl + path, {
                json: body,
                headers: headers,
                throwHttpErrors: false,
            })
            .json();
        if (response.error === "jwt expired") {
            response.error = "Please Login Again";
        }
        return response;
    } catch (e) {
        return { error: "Something went wrong..." };
    }
}

async function createLogin() {
    try {
        const path = "/createLogin";
        const body = {};
        const response = await apiCall(path, body, undefined);
        return response;
    } catch (e) {
        return { error: "Something went wrong..." };
    }
}

async function login({ message, signature }) {
    try {
        const path = "/login";
        const body = {
            message: message,
            signature: signature,
        };
        const response = await apiCall(path, body, undefined);
        return response;
    } catch (e) {
        return { error: "Something went wrong..." };
    }
}

async function logout({ logoutAll, jwt }) {
    try {
        const path = "/logout";
        const body = {
            logoutAll: logoutAll,
        };
        const response = await apiCall(path, body, jwt);
        return response;
    } catch (e) {
        return { error: "Something went wrong..." };
    }
}

async function getUserData({ jwt }) {
    try {
        const path = "/getUserData";
        const body = {

        };
        const response = await apiCall(path, body, jwt);
        return response;
    } catch (e) {
        return { error: "Something went wrong..." };
    }
}

async function issueCard({  name, email, phoneNumber, addressLine1, city, state, postalCode, country, jwt }) {
    try {
        const path = "/issueCard";
        const body = {
            name, email, phoneNumber, addressLine1, city, state, postalCode, country
        };
        const response = await apiCall(path, body, jwt);
        return response;
    } catch (e) {
        return { error: "Something went wrong..." };
    }
}

const api = {
    login,
    logout,
    createLogin,
    getUserData,
    issueCard
}

export default api