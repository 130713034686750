import React from "react";

import styled, { css } from "styled-components";
import { SizedBox } from "./SizedBox";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { useAppColors } from "../utils/useAppColors";

const TitleTextWrapper = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "34px")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;

const TitleTextWrapperMobile = styled.div`
  color: ${(props) => props.color};
  font-weight: bold;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "26px")};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
`;

export const TitleText = ({ children, textAlign, color, fontSize }) => {
  const colors = useAppColors();
  return (
    <div>
      <BrowserView>
        <TitleTextWrapper
          textAlign={textAlign}
          fontSize={fontSize}
          color={color ? color : colors.titleColor}
        >
          {children}
        </TitleTextWrapper>
      </BrowserView>
      <MobileView>
        <TitleTextWrapperMobile
          textAlign={textAlign}
          color={color ? color : colors.titleColor}
          fontSize={fontSize}
        >
          {children}
        </TitleTextWrapperMobile>
      </MobileView>
    </div>
  );
};
