import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom"; import { AppContext } from './contexts/AppContext';
import { Col } from 'react-bootstrap';
import MainRoutes from './components/MainRoutes';
import NoMatch from './pages/NoMatch';
import StuckSnackbar from './components/Snackbar';
import { useEffect, useState } from 'react';
import firebaseUtils from "./utils/firebase";
import api from './utils/api';
import { ethers } from 'ethers';
import localstorage from './utils/localstorage';
import { StuckNav } from './components/StuckNav';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [provider, setProvider] = useState(null);
  const [account, setAccount] = useState(undefined);
  const [signer, setSigner] = useState(undefined)
  const [user, setUser] = useState(null);
  const [jwt, setJwt] = useState(null);
  const [theme, setTheme] = useState(undefined);
  const [isLoggingIn, setIsLoggingIn] = useState(true);
  const [globalSnackbarMessage, setGlobalSnackbarMessage] = useState("");
  const [showGlobalSnackbar, setShowGlobalSnackbar] = useState(false);
  const [globalSnackbarSeverity, setGlobalSnackbarSeverity] =
    useState("success");
  const [isChangingAccount, setIsChangingAccount] = useState(false)
  const [networkId, setNetworkId] = useState(undefined);
  const [error, setError] = useState("");

  function showSnackbar({ message, severity }) {
    setGlobalSnackbarMessage(message);
    setGlobalSnackbarSeverity(severity);
    setShowGlobalSnackbar(true);
    if (severity === "error") {
      setError(message);
    }
  }

  const logout = async () => {
    setUser(undefined);
    setJwt(undefined);
    setAccount(undefined);
    localstorage.setLocalStorageJWT(undefined);
    localstorage.setLocalStorageUser(undefined);
    await api.logout({ logoutAll: false, jwt: jwt });
  };

  function getMessage({ loginUid }) {
    return `
    Welcome to Stuck Cash!
    
    nonce: ${loginUid}
    `;
  }

  const login = async () => {
    if (provider) {
      // await web3Handler();
      if (!window.ethereum) {
        throw new Error("No crypto wallet found. Please install it.");
      }

      const { chainId } = await provider.getNetwork();
      setNetworkId(chainId);

      if (chainId !== 1 && chainId !== 5) {
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
        return;
      }
      setIsLoggingIn(true);
      const loginResp = await api.createLogin();
      setIsLoggingIn(false);

      if (loginResp.error) {
        showSnackbar({ message: loginResp.error, severity: "error" });
        firebaseUtils.log({
          eventName: "createLogin error",
          eventParams: { error: loginResp.error },
        });
        return;
      }
      firebaseUtils.log({
        eventName: "createLogin",
        eventParams: { uid: loginResp.login._id },
      });
      await window.ethereum.send("eth_requestAccounts");
      let _provider = new ethers.providers.Web3Provider(window.ethereum);
      const _signer = _provider.getSigner();
      setSigner(signer)
      const message = getMessage({ loginUid: loginResp.login._id });
      const signature = await _signer.signMessage(message);
      setIsLoggingIn(true);

      const resp = await api.login({ message: message, signature: signature });
      if (resp.error) {
        setUser(undefined);
        setJwt(undefined);
        setIsLoading(false);
        setIsLoggingIn(false);
        showSnackbar({ message: resp.error, severity: "error" });
        firebaseUtils.log({
          eventName: "login error",
          eventParams: { error: resp.error },
        });
        return;
      }

      setUser(resp.user);
      setJwt(resp.token);
      // await firebaseUtils.signinWithFirebaseToken(resp.firebaseToken);
      if (_signer && _signer.getAddress) {
        const a = await _signer.getAddress();
        setAccount(a);
      }
      firebaseUtils.log({
        eventName: "login",
        eventParams: { uid: resp.user._id },
      });

      setIsLoggingIn(false);

      return;
    } else {
      setIsLoggingIn(false);
      showSnackbar({
        message: "Please Connect an Ethereum Wallet via Metamask",
        severity: "error",
      });
    }
  };

  async function setBlockchainData() {
  }

  const loadBlockchainData = async () => {
    if (provider) {
      console.log("loadBlockchainData")
      const { chainId } = await provider.getNetwork();
      setNetworkId(chainId);


      if (chainId !== 1) {
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
        return
      }

      try {

        setBlockchainData();
      } catch (e) {
        console.log(e)
      }

      // if (chainId !== 1) {
      //   showSnackbar({
      //     message: "Please switch to the Ethereum Network",
      //     severity: "error",
      //   });
      // }
      // const networkId
    }
  };

  const loadWeb3 = async () => {
    if (typeof window.ethereum !== "undefined" && !account) {
      const _provider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(_provider);
      const { chainId } = await _provider.getNetwork();
      setNetworkId(chainId);

      if (chainId !== 1 && chainId !== 5) {
        showSnackbar({
          message: "Please switch to the Ethereum Network",
          severity: "error",
        });
      }
      const _account = await _provider.getSigner();
      setSigner(_account)
      if (_account && _account.getAddress) {
        const a = await _account.getAddress();
        if (a !== account) {
          setAccount(a);
        }
      } else {
        showSnackbar({
          message: "Please connect with MetaMask",
          severity: "error",
        });
      }

      window.ethereum.on("accountsChanged", function (accounts) {
        if (accounts[0] !== account) {
          setAccount(accounts[0]);
        }
      });

      window.ethereum.on("chainChanged", (chainId) => {
        window.location.reload();
      });
    }
  };

  async function getInitialData() {
    try {
      let response;
      const j = localstorage.getLocalStorageJwt();
      const t = localstorage.getLocalStorageTheme();
      if (!t) {
        setTheme("light");

        document.body.classList.remove("dark-body");
      } else {
        setTheme(t);
      }
      if (!j) {
        // response = await api.getSharedInfo();
        // if (response.error) {
        //   window.alert(response.error);
        //   return;
        // }
        setIsLoggingIn(false)
        return;
      }
      setIsLoggingIn(true);
      response = await api.getUserData({ jwt: j });
      if (!response || !response.user) {
        throw new Error("Please login again");
      }

      if (response.error) {
        setUser(undefined);
        setJwt(undefined);
        localstorage.setLocalStorageJWT(undefined);
        localstorage.setLocalStorageUser(undefined);
        firebaseUtils.log({
          eventName: "getUserData error",
          eventParams: { error: response.error },
        });

        throw new Error(response.error);
        // response = await api.getSharedInfo();W
        return;
      }

      setUser(response.user);
      setJwt(j);
      firebaseUtils.log({
        eventName: "getInitialData getUserData",
        eventParams: { uid: response.user._id },
      });
      // response = await api.getSharedInfo();
      setIsLoggingIn(false);
    } catch (e) {
      console.log("InitialData Error: ", e);
      setUser(undefined);
      setJwt(undefined);
      localstorage.setLocalStorageJWT(undefined);
      localstorage.setLocalStorageUser(undefined);
      setIsLoggingIn(false);
      try {
        firebaseUtils.log({
          eventName: "error",
          eventParams: { error: e },
        });
      } catch (e) { }
      showSnackbar({ message: e, severity: "error" });
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  async function handleAccountChange(){
    setIsChangingAccount(true)
    await loadBlockchainData();

    if (account && user && account.toLowerCase() !== user.evmAddress.toLowerCase()) {
      await logout();
    } else {
      await loadWeb3();
    }
    setIsChangingAccount(false)
  }

  useEffect(() => {
    if(!isChangingAccount){
      console.log("handle account change: ", account, " ", isChangingAccount)
      handleAccountChange()
    }
  }, [account]);


  return (
    <div className="App">
      <AppContext.Provider value={{
        user,
        setUser,
        jwt,
        setJwt,
        provider,
        account,
        theme,
        setTheme,
        login,
        logout,
        showSnackbar,
        networkId,
        setNetworkId,
        setBlockchainData,
        signer,
        setSigner,
        isLoggingIn,
        log: firebaseUtils.log,
      }}>
        <StuckNav isLoggingIn={isLoggingIn} />
        <Col className="d-flex flex-column justify-content-between align-items-between"
          style={{ height: "100%" }}>
          <Routes>
            <Route
              path="/*"
              element={<MainRoutes isLoggingIn={isLoggingIn} />}
            />
            <Route path="*" element={<NoMatch />} />
          </Routes>
        </Col>
        <StuckSnackbar
          open={showGlobalSnackbar}
          setOpen={setShowGlobalSnackbar}
          message={globalSnackbarMessage}
          severity={globalSnackbarSeverity}
        />
      </AppContext.Provider>
    </div>
  );
}


function MyApp() {
  return (
    <div id={"app"} className="app2d">
      <Router>
        <App />
      </Router>
    </div>
  );
}

export default MyApp;
