import { useContext, useState } from "react";
import { Col } from "react-bootstrap";
import { CustomTextField } from "../components/CustomTextInput";
import { PrimaryButton } from "../components/PrimaryButton";
import { SizedBox } from "../components/SizedBox";
import { TitleText } from "../components/TitleText";
import { WidthContainer } from "../components/WidthContainer";
import { AppContext } from "../contexts/AppContext";
import api from "../utils/api";


export default function Home() {
    const {user, setUser, jwt,  showSnackbar} = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(true)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [addressLine1, setAddressLine1] = useState("")
    const [city, setCity] = useState("")
    const [state, setState] = useState("")
    const [postalCode, setPostalCode] = useState("")
    const [country, setCountry] = useState("US")

    async function createCard(){
        try{
            setIsLoading(true)
            const name = `${firstName.trim()} ${lastName.trim()}`
            const response = await api.issueCard({name, email, phoneNumber, addressLine1, city,state, postalCode, country, jwt })
            if(response.error){
                throw Error(response.error)
            }
            setUser(response.user)
            setIsLoading(false)
        }catch(e){
            setIsLoading(false)
            showSnackbar({message:e.message,severity:"error" })
        }

    }

    console.log("user: ", user)
    return <div>
        <WidthContainer width={"40%"} >
            {!user && <Col>
                <TitleText>
                    Pls Login
                </TitleText>
            </Col>}
            {user && !user.cardHolderId && <Col>
                <CustomTextField name={"First Name"} placeholder={"Enter First Name"} value={firstName} setValue={setFirstName} />
                <SizedBox height={12} />
                <CustomTextField name={"Last Name"} placeholder={"Enter Last Name"}   value={lastName} setValue={setLastName}/>
                <SizedBox height={12} />
                <CustomTextField name={"Email"} placeholder={"Enter Email"} value={email} setValue={setEmail} />
                <SizedBox height={12} />
                <CustomTextField name={"Phone Number"} placeholder={"Enter Phone Number"} value={phoneNumber} setValue={setPhoneNumber} />
                <SizedBox height={12} />
                <CustomTextField name={"Address Line 1"} placeholder={"Enter Address Line 1"} value={addressLine1} setValue={setAddressLine1} />
                <SizedBox height={12} />
                <CustomTextField name={"City"} placeholder={"Enter City"} value={city} setValue={setCity} />
                <SizedBox height={12} />
                <CustomTextField name={"State"} placeholder={"Enter State ex FL"} value={state} setValue={setState} />
                <SizedBox height={12} />
                <CustomTextField name={"Zip Code"} placeholder={"Enter Zip Code"} value={postalCode} setValue={setPostalCode} />
                <SizedBox height={12} />
                <PrimaryButton text="Submit"  onClick={createCard}/>
                <SizedBox height={64} />
            </Col>}
        </WidthContainer>
    </div>
}